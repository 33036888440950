import _ from 'lodash';
import { createSelector } from 'reselect';
import { getRentPriceInfo, getSalePriceInfo, getSubscriptionPriceInfo, getPricingPlanInfo } from '@wix/wix-vod-shared/dist/src/common/utils/deal-info-helpers';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideoById } from 'shared/selectors/videos';
export function isPayed(entity) {
    return Boolean(_.get(entity, 'dgsInfo'));
}
export var getChannelDealInfo = createSelector(getChannelForWidget, _.property('dealInfo'));
export var getSubscribeInfo = createSelector(getChannelDealInfo, function (dealInfo) { return getSubscriptionPriceInfo(dealInfo) || getPricingPlanInfo(dealInfo); });
export var getVideoDealInfo = createSelector(getVideoById, _.property('dealInfo'));
export var getRentInfo = createSelector(getVideoDealInfo, getRentPriceInfo);
export var getSaleInfo = createSelector(getVideoDealInfo, getSalePriceInfo);
