var _a;
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as CHANNEL_PAYMENT } from 'widget/redux/server/actions/channel/payment.js';
var PATH_TO_CHANNELS = 'response.data.entities.channels';
var defaultState = {};
export default handleActions((_a = {},
    _a[CHANNEL_PAYMENT.SUCCESS] = function (state, _a) {
        var payload = _a.payload;
        var _b;
        if ((_b = payload.response) === null || _b === void 0 ? void 0 : _b.pricingPlan) {
            return payload.response;
        }
        else {
            var channels = _.get(payload, PATH_TO_CHANNELS);
            var channelPaymentData = _.values(channels)[0];
            return channelPaymentData || {};
        }
    },
    _a), defaultState);
